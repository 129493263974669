<template>
  <div id="security">
    <div class="border-bottom pb-4">
      <h5>Update your password</h5>
      <form ref="updatePasswordForm" class="row" v-on:submit.prevent="onSubmit">
        <div class="col-12 form-group">
          <label for="password">Your current password:</label>
          <input type="password"
            name="password"
            id="password"
            class="form-control"
            v-model="$v.form.password.$model"
            v-bind:class="{ 'is-invalid': $v.form.password.$error, }">
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.password.$error, }">
            <span v-if="!$v.form.password.required">This field is required</span>
          </div>
        </div>
        <div class="col-12 col-md-6 form-group">
          <label for="new_password">Your current password:</label>
          <input type="password"
            name="new_password"
            id="new_password"
            class="form-control"
            v-model="$v.form.newPassword.$model"
            v-bind:class="{ 'is-invalid': $v.form.newPassword.$error, }">
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.newPassword.$error, }">
            <span v-if="!$v.form.newPassword.required">This field is required</span>
            <span v-if="!$v.form.newPassword.minLength">This is not a secure password (8 characters min.)</span>
          </div>
        </div>
        <div class="col-12 col-md-6 form-group">
          <label for="new_password_confirmation">Your current password:</label>
          <input type="password"
            name="new_password_confirmation"
            id="new_password_confirmation"
            class="form-control"
            v-model="$v.form.newPasswordConfirmation.$model"
            v-bind:class="{ 'is-invalid': $v.form.newPasswordConfirmation.$error, }">
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.newPasswordConfirmation.$error, }">
            <span v-if="!$v.form.newPasswordConfirmation.required">This field is required</span>
            <span v-if="!$v.form.newPasswordConfirmation.minLength">This is not a secure password (8 characters min.)</span>
            <span v-if="!$v.form.newPasswordConfirmation.sameAs">Password confirmation doesn't match</span>
          </div>
        </div>
        <div class="col-12 text-right">
          <button class="btn btn-primary">Update password</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { validationMixin, } from "vuelidate";
  import { required, minLength, sameAs, } from "vuelidate/lib/validators";
  export default {
    mixins: [
      validationMixin,
    ],
    data() {
      return {
        form: {
          password: "",
          newPassword: "",
          newPasswordConfirmation: "",
        },
      };
    },
    methods: {
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.$swal(
          "Congrats!",
          "Your password has been updated successfully",
          "success",
        );
        this.$router.push({ name: "Profile", });
      }
    },
    validations: {
      form: {
        password: { required, },
        newPassword: { required, minLength: minLength(8), },
        newPasswordConfirmation: { required, minLength: minLength(8), sameAs: sameAs("newPassword") },
      }
    }
  }
</script>